/**
 * author: Patrick Vyn Badiang
 * 
 * description:
 * is the DOM
 */
import MainHeader from './utils/MainHeader.js';
import CssBaseline from '@mui/material/CssBaseline';


import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { themeOptions } from './ThemeOptions.tsx';

import { ThemeProvider, createTheme } from '@mui/material/styles';

//utils
import MemberHighlight from './components/pages/memberPage/MemberHighlight.js';
import { Box, Paper } from '@mui/material';
import Divider from './utils/Divider.js';
import BottomFooter from './utils/BottomFooter.js';
import Main from './components/pages/mainPage/Main.js';


//Pages
import BoardPage from './components/pages/boardPage/BoardPage';
import ConstitutionPage from './components/pages/constitutionPage/ConstitutionPage';
import ResourcesPage from './components/pages/resourcesPage/ResourcesPage';
import DonationPage from './components/pages/donationPage/DonationPage';
import FormsPage from './components/pages/eventsPage/FormsPage';
import PicturesPage from './components/pages/picturesPage/PicturesPage';


function App() {
  const fasaName = "FASA at VT";
  const theme = createTheme(themeOptions);  
  
  const styles = {
    container: {
        position: 'relative',
        width: '100%',
        marginTop: '0px',
      },
      image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        marginTop: '100px',
      },
};

  return (
    
    <ThemeProvider theme={theme}>

        <CssBaseline />
        
        <BrowserRouter>
        <div>
          <MainHeader text = {fasaName}/>
        </div>
        <img
          src='/pictures/fasa_pic.png' // Replace with the actual path to your image
          alt = 'cover'
          style={styles.image}/>
      <main>
        <Routes>
        
          <Route path ="/" element = {<Main theme = {theme}/>} />
          <Route path ="board" element = {<BoardPage theme = {theme}/>} />
          <Route path ="memberHighlight" element = {<MemberHighlight theme = {theme}/>} />
          <Route path = "constitution" element = {<ConstitutionPage theme = {theme}/>}/>
          <Route path = "resources" element = {<ResourcesPage theme = {theme}/>}/>
          <Route path = "donation" element = {<DonationPage theme = {theme}/>}/>
          <Route path = "fasaPictures" element = {<PicturesPage theme = {theme}/>}/>
          <Route path = "forms" element = {<FormsPage theme = {theme}/>}/>

          
        </Routes>
      </main>
      <Paper sqaure sx = {{width: 'full'}}>
                <Box sx = {{height: '20px'}} mt = {10}/>
                <Divider color = '#224343'/>
                <BottomFooter/>
            <Box sx = {{height: '30px'}}/>
          </Paper>
    </BrowserRouter>
    </ThemeProvider>
    

      
  );
}

export default App;

