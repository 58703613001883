/**
 * author: Patrick Vyn Badiang
 * 
 * description:
 * After declaring the header text, it calls ResourcesTemplate
 * and passes an array of elements it wants to be displayed below it.
 */


import { Typography} from "@mui/material";

import ResourcesTemplate from "./ResourcesTemplate";
import HealthData from "./HealthData.json";
import AdvocacyData from "./AdvocacyData.json";
import LanguageData from "./LanguageData.json";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CustomDivider from '../../../utils/SmallerDivider';



function ResourcesPage() {

    
    
    return(
        <>
            
              <Typography variant="h3" align="center">
                Mental Health
              </Typography>
              <CustomDivider color = "green"/>

              
              <Grid container direction={'column'} spacing={4}>
                <Grid item>
                    <ResourcesTemplate section = "Health"
                        color = "#00A36C"
                        images = {HealthData}/>
                </Grid>
                <Grid item>
                    <Typography variant="h3" align="center">
                        Other Organizations for Advocacy
                    </Typography>
                    
                    <CustomDivider color = "#FFBF00"/>
                        
                        
                    <ResourcesTemplate section = "Advocacy"
                        color = "#FFBF00"
                        images = {AdvocacyData}/>

                </Grid>
                <Grid item>
                    <Typography variant="h3" align="center">
                        Links to learn our language: Tagalog!
                    </Typography>
                    <CustomDivider color = "red"/>
                    <ResourcesTemplate 
                    color = "#AA4A44"
                        images = {LanguageData}/>
                </Grid>
              

              
              
              </Grid>

              
              
        </>
    );
}

export default ResourcesPage;