/**
 * author: Patrick Vyn Badiang
 *
 * description:
 *  Is the mainPage for forms
 */

import { Paper, Typography } from "@mui/material";

import FASAFormPage from "./FASAFormPage";
import Section from "../mainPage/Section";

import FasaData from "../../../databases/FasaData.json";

import SlidesData from "../../../databases/SlidesData.json";

function EventsPage() {
  const styles = {
    container: {
      position: "relative",
      width: "100%",
      marginTop: "0px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      marginTop: "100px",
    },
    paper: {
      position: "absolute",
      bottom: "40px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "fit-content",
      padding: "20px",
    },
  };

  return (
    <>
      <div style={styles.container}>
        <Paper style={styles.paper}>
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: "1rem", // Extra-small devices (phones)
                sm: "1.5rem", // Small devices (tablets)
                md: "2rem", // Medium devices (desktops)
                lg: "3rem", // Large devices (large desktops)
              },
            }}
          >
            Forms and Slides
          </Typography>
        </Paper>
      </div>
      <div style={{ marginLeft: 30, marginTop: 50 }} />
      
      <Section text="FASA Forms" />
      <FASAFormPage forms={FasaData} />
      {/*  Fasa Fit data is now within FasaData */}


      <Section text="Slides" />
      <FASAFormPage forms={SlidesData} />
    </>
  );
}

export default EventsPage;
