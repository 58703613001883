/**
 * author: Patrick Vyn Badiang
 *
 * Description:
 *
 * Is used for any section
 * Depending on whether or not specific props exist,
 * itll then display accordingly
 *
 * Ex: If it has props.subText then it'll create a text area
 * But if there is no props.subText and it has a props.photo
 * then it'll create a photo component
 */
import { Typography, Paper, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const CustomPaper = styled(Paper)({
  background: "#e8dab3",
});

const Section = (props) => {
  return (
    <Grid container xs={12}>
      <Grid xs />
      <Grid xs={4}>
        <CustomPaper>
          <Typography variant="h3" align="center">
            {props.text}
          </Typography>
        </CustomPaper>
      </Grid>
      <Grid xs />
    </Grid>
  );
};

export default Section;
