/**
 * author: Patrick Vyn Badiang
 *
 * description:
 * Is the pop up for the emails.
 * Is centered with the help of the Grid components
 */
import * as React from "react";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
//TextField,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, Snackbar, Typography } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import NewsLetter from "../../../pictures/NewLetter.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";

function EmailFormDialog() {
  const link = "https://mailchi.mp/31e1b05e2f48/fasa-newsletter-signup";
  const emailURL =
    "https://vtfasa.us20.list-manage.com/subscribe/post?u=b0af669fc48f9e20fbfec22dd&amp;id=15d05ee128&amp;f_id=004a78eaf0";

  //Is set true so that it opens when it first loads
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const [success, setSuccess] = React.useState(false);

  const closeSuccess = () => {
    setSuccess(false);
  };

  // simplest form (only email)
  const SimpleForm = () => <MailchimpSubscribe url={emailURL} />;

  return (
    <div>
      <Grid container xs={12} justify="center" alignItems="center">
        <Grid item>
          <Dialog open={open} onClose={handleClose} component="form">
            <DialogTitle>Subscribe To Our Newsletter!</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                direction={"row"}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <img
                    style={{
                      resizeMode: "contain",
                      maxHeight: 100,
                      maxWidth: 150,
                    }}
                    src={require("../../../pictures/NewLetter.png")}
                  />
                </Grid>

                <Grid item xs>
                  <DialogContentText>
                    To receive emails about upcoming events and updates, please
                    enter your email address here.
                  </DialogContentText>

                  <Button
                    href={link}
                    target="_blank"
                    rel="noopener"
                    variant="outlined"
                  >
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Done</Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={closeSuccess}
            message="Email Added"
          >
            <Alert open={success} onClose={closeSuccess}>
              <Typography variant="body1">Email Added Successfully</Typography>
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs />
      </Grid>
    </div>
  );
}

export default EmailFormDialog;
