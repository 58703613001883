import { Paper, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";



const CustomPaper = styled(Paper)(({ theme }) => ({
    background: "#FFC000",
  }));

const BoardSection = (props) => {
    return (
        <>
        <Grid item xs> {/* This is the Senior Advisors */}
          <CustomPaper>
            <Typography variant="h3">
              {props.title}
            </Typography>
          </CustomPaper>
        </Grid>
        <Grid item xs>
        {props.element}
        </Grid>
        </>
    )
}

export default BoardSection;