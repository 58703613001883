import { Box, Paper, Typography } from "@mui/material";
// import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BottomFooter from "../../../utils/BottomFooter";
import Divider from "../../../utils/Divider";
import { styled } from '@mui/system';



const CustomPaper = styled(Paper)(({ theme }) => ({
    background: '##224343',
    
  }));

function MemberHighlight(){
    // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // const youtube = 'https://youtu.be/vQ4zeBsLpqM';

    
    const styles = {
        container: {
            position: 'relative',
            width: '100%',
            marginTop: '0px',
          },
          image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            marginTop: '100px'
          },
          paper: {
            position: 'absolute',
            bottom: '-40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'fit-content',
            padding: '20px',
          },
    };



    return (<div>
              <div style={styles.container}>
                <img
                  src='/pictures/fasa_pic.png' // Replace with the actual path to your image
                  alt = 'cover'
                  style={styles.image}/>
                <CustomPaper style={styles.paper}
                elevation={8}>
                  <Typography variant="h3"
                  sx = {{
                      fontSize: { 
                        //has different breaksizes so that it's mobile compatible

                        xs: '1rem',   // Extra-small devices (phones)
                        sm: '1.5rem', // Small devices (tablets)
                        md: '2rem',   // Medium devices (desktops)
                        lg: '3rem',   // Large devices (large desktops)
                      }
                  }}>
                    Member Of the Months
                  </Typography>
                </CustomPaper>
              </div>
              
            <Paper sqaure sx = {{width: 'full'}}>
                <Box sx = {{height: '20px'}} mt = {10}/>
                <Divider color = '#224343'/>
                <BottomFooter/>
            <Box sx = {{height: '30px'}}/>
          </Paper>
        </div>
        
       
    )
}

export default MemberHighlight;