/**
 * author: Patrick Vyn Badiang
 *
 * description:
 * Is the board Page manager
 */
import BoardMembers from "./BoardMembers";
import { Card, Paper, Typography, useMediaQuery } from "@mui/material";

import { YouTubeEmbed } from "react-social-media-embed";

import { styled } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

const CustomPaper = styled(Paper)(({ theme }) => ({
  background: "#FFC000",
}));

function BoardPage() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const youtube = "https://youtu.be/B_D36YvTYRE?si=__ze-d2AN-oifUuj";

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      marginTop: "0px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      marginTop: "100px",
    },
    paper: {
      position: "absolute",
      bottom: "-40px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "fit-content",
      padding: "20px",
    },
  };

  return (
    <>
      <div style={styles.container}>
        <CustomPaper style={styles.paper} elevation={8}>
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                //has different breaksizes so that it's mobile compatible

                xs: "1rem", // Extra-small devices (phones)
                sm: "1.5rem", // Small devices (tablets)
                md: "2rem", // Medium devices (desktops)
                lg: "3rem", // Large devices (large desktops)
              },
            }}
          >
            2024 Officer Video!
          </Typography>
        </CustomPaper>
      </div>
      <Grid container xs={12} mt={10} spacing={1}>
        <Grid xs />
        <Grid xs={8}>
          <Card sx={{ borderRadius: "20px", aspectRatio: 3 / 2 }}>
            {isSmallScreen ? (
              <YouTubeEmbed
                url={youtube}
                height={250}
                width="100%"
                align="center"
              />
            ) : (
              <YouTubeEmbed
                url={youtube}
                height={800}
                width="100%"
                align="center"
              />
            )}
          </Card>
        </Grid>
        <Grid xs />
      </Grid>

      <Grid container xs={12}>
        <Grid item xs>
          <BoardMembers />
        </Grid>
      </Grid>
    </>
  );
}

export default BoardPage;
