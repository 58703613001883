/**
 *  author: Patrick Vyn Badiang
 *
 *  Description:
 *  Call the CardWithDropDown component whenever we want
 *  to add a new member.
 *
 *  Passes down the image as well as a title and description
 */
import { Box, Card, CardMedia, useMediaQuery } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ExecutiveBoard from "./DifferentCommitties/ExecutiveBoard";
import WebMaster from "./DifferentCommitties/WebMaster";

import seniorAdvisor from "../../../pictures/ExecutiveBoard/SeniorAdvisor.jpg";
import SmallOfficerBoard from "./DifferentCommitties/SmallOfficerBoard";
import BigOfficerBoard from "./DifferentCommitties/BigOfficierBoard";
import BoardSection from "../../../utils/BoardSection";

//All member pictures
import historian from "../../../pictures/OfficerBoard/Historians.jpg";
import d7 from "../../../pictures/OfficerBoard/D7.jpg";
import culture from "../../../pictures/OfficerBoard/Culture.jpg";
import events from "../../../pictures/OfficerBoard/Events.jpg";
import fundraising from "../../../pictures/OfficerBoard/Fundraising.jpg";
import hospitality from "../../../pictures/OfficerBoard/Hospitality.jpg";
import promo from "../../../pictures/OfficerBoard/Promo.jpg";
import service from "../../../pictures/OfficerBoard/Service.jpg";
import sports from "../../../pictures/OfficerBoard/Sports.jpg";

function BoardMembers() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ marginTop: "50px" }}>
      <Grid
        container
        xs={12}
        direction={"column"}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {/* This is the Executive Board */}
        <BoardSection title="Executive Board" element={<ExecutiveBoard />} />

        {/* This is the Senior Advisors */}
        <BoardSection
          title="Senior Advisors"
          element={
            <Card sx={{ borderRadius: "30px" }}>
              <CardMedia
                component="img"
                image={seniorAdvisor}
                alt="Image"
                sx={{ maxWidth: 500 }}
              />
            </Card>
          }
        />

        {/* This is the Web Masters */}
        <BoardSection title="Web  Masters" element={<WebMaster />} />

        {/* This is the Officer Board */}
        <BoardSection
          title="Officer Board"
          element={
            isSmallScreen ? (
              <SmallOfficerBoard
                historian={historian}
                d7={d7}
                culture={culture}
                events={events}
                fundraising={fundraising}
                hospitality={hospitality}
                promo={promo}
                service={service}
                sports={sports}
              />
            ) : (
              <BigOfficerBoard
                historian={historian}
                d7={d7}
                culture={culture}
                events={events}
                fundraising={fundraising}
                hospitality={hospitality}
                promo={promo}
                service={service}
                sports={sports}
              />
            )
          }
        />
      </Grid>
    </Box>
  );
}

export default BoardMembers;
