/**
 * author: Patrick Vyn Badiang
 * 
 * description:
 * is the constitution page manager
 * Calls a new article component for each article section
 * gives it the path of the file  and gives a title.
 */

import { Typography, Paper } from "@mui/material";
import { styled } from '@mui/system';
import Divider from "../../../utils/Divider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Article from "./Article";


const CustomPaper = styled(Paper)(({ theme }) => ({
    background: '#FFC000',    
  }));


function ConstitutionPage() {

    const styles = {
        container: {
            position: 'relative',
            width: '100%',
            marginTop: '0px',
          },
          image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            marginTop: '100px'
          },
          paper: {
            position: 'absolute',
            bottom: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'fit-content',
            padding: '20px',
          },
    };

   

    return(
        <>
            <div style={styles.container} >
                
                <CustomPaper style={styles.paper}>
                  <Typography variant="h3"
                  sx = {{
                      fontSize: {
                        xs: '1rem',   // Extra-small devices (phones)
                        sm: '1.5rem', // Small devices (tablets)
                        md: '2rem',   // Medium devices (desktops)
                        lg: '3rem',   // Large devices (large desktops)
                      }
                  }}>
                    Constitution
                  </Typography>
                </CustomPaper>
              </div>
              <div style = {{marginTop: '50px'}}>
                <Divider color = "black"/>
                <Grid container spacing={2} direction={'column'}>
                    <Grid item>
                        <Article  title = "Article I: The Organization" 
                        path = '/textfiles/Constitution/ArticleI.txt'/>
                    </Grid>
                    <Grid item>
                        <Article  title = "Article II: Membership" path = '/textfiles/Constitution/ArticleII.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article III: Meeting Classification"
                        path = '/textfiles/Constitution/ArticleIII.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article IV: Event Determination"
                        path = '/textfiles/Constitution/ArticleIV.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article V: Conduct"
                        path = '/textfiles/Constitution/ArticleV.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article VI: Executive Board"
                        path = '/textfiles/Constitution/ArticleVI.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article VII: Officer Board"
                        path = '/textfiles/Constitution/ArticleVII.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article VIII: Impeachment"
                        path = '/textfiles/Constitution/ArticleVIII.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article IX: Scholarship"
                        path = '/textfiles/Constitution/ArticleIX.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article X. Amending the Constitution"
                        path = '/textfiles/Constitution/ArticleX.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article XI: Executive Board Duties and Powers"
                        path = '/textfiles/Constitution/ArticleXI.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article XII: Officer Board Duties and Powers"
                        path = '/textfiles/Constitution/ArticleXII.txt'/>
                    </Grid>
                    <Grid item>
                        <Article title = "Article XIII: Revisions to the  Constitution"
                        path = '/textfiles/Constitution/ArticleXIII.txt'/>
                    </Grid>

                </Grid>
                
              </div>
              
              

              
        </>
    );
}

export default ConstitutionPage;