/**
 *  author: Patrick Vyn Badiang
 *
 *  Description:
 *  is the main page, contains everything
 *  we want to be shown on the first page
 */

import Section from "./Section";

//Photos

import Grid from "@mui/material/Unstable_Grid2";

import Updates from "../../../utils/Updates";
import BordordConerSection from "../../../utils/BordordCornerSection";

//Section Texts
import SummaryOfFasa from "../../../utils/SummaryOfFasa";
import Scholarship from "../../../utils/Scholarship";
import EmailFormDialog from "./EmailFormDialog";
import BoardPage from "../boardPage/BoardPage";
import { Box } from "@mui/material";

const MainPage = (props) => {
  //Section Titles
  const events = "Updates";

  return (
    <>
      <Grid container xs={12} direction={"column"} spacing={2}>
        <Grid item>
          <EmailFormDialog />
        </Grid>
        <Grid>
          <BordordConerSection inside={<SummaryOfFasa />} />
        </Grid>
        <Grid>
          <Section text={events} />
          <Updates sx={{ marginTop: "10px" }} />
        </Grid>
        <Grid item>
          <Box sx={{ marginTop: "10px", height: "100px" }} />
          <BordordConerSection inside={<Scholarship />} />
        </Grid>
        <Grid item xs={12} />
        <Grid item>
          <BoardPage />
        </Grid>
      </Grid>
    </>
  );
};

export default MainPage;
