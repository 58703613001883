/**
 * author: Patrick Vyn Badiang
 *
 * description:
 * is the oBoard manager with a new row every 7 members
 */
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Card, CardMedia } from "@mui/material";


const SmallOfficerBoard = (props) => {
  return (
    <Grid container xs={12} mt={5} ml={1} spacing={2} direction={"row"}>
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.historian}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.d7}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={2} />

      {/*New Row*/}
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.culture}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.events}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={2} />

      {/*New Row*/}
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.fundraising}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.hospitality}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={2} />

      {/*New Row*/}
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.promo}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.service}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={2} />

      {/*New Row*/}
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.sports}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px" }}>
          <CardMedia
            component="img"
            image={props.d7}
            alt="Image"
            sx={{ maxWidth: 550 }}
          />
        </Card>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}

export default SmallOfficerBoard;
