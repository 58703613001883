/**
 * Is the pictures page
 */
import { Paper, Typography } from "@mui/material";


import FASAFormPage from "../eventsPage/FASAFormPage";
import PicturesData from '../../../databases/PicturesData.json';

function PicturesPage() {
    const styles = {
        container: {
            position: 'relative',
            width: '100%',
            marginTop: '0px',
          },
          image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            marginTop: '100px'
          },
          paper: {
            position: 'absolute',
            bottom: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'fit-content',
            padding: '20px',
          },
    };



    return(
        <>
        <div style={styles.container} >
            
            <Paper style={styles.paper}>
              <Typography variant="h3"
              sx = {{
                  fontSize: {
                    xs: '1rem',   // Extra-small devices (phones)
                    sm: '1.5rem', // Small devices (tablets)
                    md: '2rem',   // Medium devices (desktops)
                    lg: '3rem',   // Large devices (large desktops)
                  }
              }}>
                Pictures
              </Typography>
            </Paper>
          </div>
          <div style = {{marginLeft: 30, marginTop: 50}}/>
            <FASAFormPage forms = {PicturesData}/>
            
        
    </>
    


    );

}

export default PicturesPage;